import { useContext } from 'react'
import { AuthContext } from './auth/context'

/**
 * Specific user regions that we base regions off of
 */
export enum UserRegion {
  US = 'US',
  JP = 'JP'
}

export const useUserRegion = (): UserRegion | string | undefined => {
  const { loggedUserProfile } = useContext(AuthContext)

  return loggedUserProfile?.country_code.toUpperCase() || undefined
}
