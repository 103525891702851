import React, { useState, useContext, useEffect, useMemo, useCallback, lazy, Suspense } from 'react'
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Mascots } from '@pokemon/design.ui.containers.background'
import { AccountPicker, AccountPickerAccount, displayName } from '@pokemon/design.ui.menu.account-picker'
import { MenuItemProps } from '@pokemon/design.ui.menu.sidebar-menu/menu-item'
import './app.css'
import { ChangePasswordPage } from './pages/change_password_page/change_password_page'
import { ErrorPageNotFound } from './pages/error_pages/error_page_not_found'
import { ChildAccCreation } from './pages/child_acc_creation/child_acc_creation'
import { ErrorPage } from './pages/error_pages/error_page'
import { SidebarMenu, SideBarMenuContext, SideBarMenuContextValue } from '@pokemon/design.ui.menu.sidebar-menu'
import { HeaderLogo, AppInfo, generateAppGameLinks, sortAppInfoArray } from './utils/utils'
import { AppWrapper, Footer } from './template'
import { AuthContext, AuthContextValue } from './utils/auth/context'
import { useMediaQuery, useTheme } from '@mui/material'
import Callback from './pages/callback/callback'
import { AuthWrapper } from './utils/auth/wrapper'
import { LoadingContext, LoadingSpinner } from '@pokemon/design.ui.loading-spinner'
import { retrieveProfile, retrieveAppList, retrieveAccounts } from './utils/tamApi'
import { TopBar } from '@pokemon/design.ui.input.top-bar'
import { ModalContextProvider } from '@pokemon/design.ui.containers.base-modal'
import { PrivacyPage } from './pages/privacy/privacy_page'
import { ChangeEmailPage } from './pages/change_email/change_email_page'
import { ProfileOverviewPage } from './pages/profile_overview/profile_overview_page'
import { GenericAppGamePage } from './pages/generic_app_game_page/generic_app_game_page'
import { checkCanCreateChild, responses } from './utils/userCreationAPI'
import { ErrorCustomBody } from './pages/error_pages/error_custom_body'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { LogoutPage } from './pages/logout/logout'
import { MfaPage } from './pages/mfa/mfa'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NintendoLinkPage } from './pages/nintendo_link_page/nintendo_link_page'
import { localizeLink } from '@tpci/i18next-ext'
import config from './utils/config'
import { PlaySettingsPage } from './pages/play/play_settings'
import { UserRegion, useUserRegion } from './utils/useUserRegion'

const VerifyPage = lazy(() => import('./pages/verify'))
const ErrorPageVerificationLocked = lazy(() => import('./pages/error_pages/error_page_verification_locked'))

function App () {
  const {
    ptc8296TamMfaSettings,
    ptc8688TamVeratad,
    ptc8691TamOpSignupAndManagement,
    ptc8892TamNintendoLinkSettings,
    ptc9048UnderAocMfaSettings
  } = useFlags()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const theme = useTheme()
  const mediumOrSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const smallOrSmallerScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { accessToken, primaryUserAccount, setPrimaryUserAccount, accounts, setAccounts, setSelectedAccount, selectedAccount, isChildView, setIsChildView, setLoggedUserProfile, loggedUserProfile, setAppList, primaryUserProfile, setPrimaryUserProfile } = useContext<AuthContextValue>(AuthContext)
  const { setHideBar, setSelectedItemMainMenu, showTopMenu, setShowTopMenu } = useContext<SideBarMenuContextValue>(SideBarMenuContext)
  const [linksData, setLinksData] = React.useState<AppInfo[]>([])
  const [errorText, setErrorText] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState(false)
  const [loadingSubMenu, setLoadingSubMenu] = useState<string>()
  const userRegion = useUserRegion()
  const userIsJp = userRegion === UserRegion.JP

  const appsGamesLinks = useMemo(() => generateAppGameLinks(linksData), [linksData])

  const appsGamesSubmenu: MenuItemProps = {
    uniqueId: 'appsgames',
    name: t('menu_item_apps_games', 'Apps & Games'),
    itemType: 'mainMenuItem',
    iconSrc: 'https://static.pokemon.com/images/joystick_icon.svg',
    subMenuItems: appsGamesLinks
  }

  const profileSubmenu: MenuItemProps = {
    uniqueId: 'profile',
    name: t('menu_item_profile', 'Profile'),
    itemType: 'mainMenuItem',
    iconSrc: 'https://static.pokemon.com/images/person_filled_icon.svg',
    subMenuItems: [
      {
        uniqueId: 'profileoverview',
        name: t('menu_item_profile_overview', 'Profile Overview'),
        itemType: 'internalLink',
        to: '/profile'
      },
      {
        uniqueId: 'changeemailaddress',
        name: t('menu_item_change_email', 'Change Email Address'),
        itemType: 'internalLink',
        to: '/profile/change-email'
      },
      {
        uniqueId: 'changepassword',
        name: t('menu_item_change_password', 'Change Password'),
        itemType: 'internalLink',
        to: '/profile/change-password'
      },
      {
        uniqueId: 'privacy',
        name: t('menu_item_privacy', 'Privacy'),
        itemType: 'internalLink',
        to: '/profile/data-security'
      }
    ]
  }

  if (ptc8892TamNintendoLinkSettings && !userIsJp) {
    profileSubmenu.subMenuItems?.push({
      uniqueId: 'nintendolink',
      name: t('menu_item_nintendo_link', 'Nintendo Link'),
      itemType: 'internalLink',
      to: '/profile/nintendo-link'
    })
  }

  if (ptc8296TamMfaSettings && ((!isChildView || ptc9048UnderAocMfaSettings))) {
    profileSubmenu.subMenuItems?.push({
      uniqueId: 'mfa',
      name: t('menu_item_mfa', 'Multi-factor Authentication'),
      itemType: 'internalLink',
      to: '/profile/mfa'
    })
  }

  const mainMenuItems: MenuItemProps[] = [
    profileSubmenu,
    appsGamesSubmenu
  ]

  const shouldShowPlayPokemon = ptc8691TamOpSignupAndManagement && !userIsJp

  if (shouldShowPlayPokemon) {
    const opSubmenu: MenuItemProps = {
      uniqueId: 'playpokemon',
      name: t('menu_item_play_pokemon', 'Play! Pokémon'),
      itemType: 'mainMenuItem',
      iconSrc: 'https://static.pokemon.com/images/play_pokemon_icon.svg'
    }

    if (loggedUserProfile?.player_id) {
      opSubmenu.subMenuItems = [
        {
          uniqueId: 'playpokemonsettings',
          name: t('menu_item_play_pokemon_settings', 'Play! Pokémon Settings'),
          itemType: 'internalLink',
          to: '/play-pokemon'
        },
        {
          uniqueId: 'playpokemonpage',
          name: t('menu_item_play_pokemon_page', 'My Play! Pokémon Page'),
          itemType: 'externalLink',
          to: localizeLink(i18n.language, config.PLAY_PAGE_URL)
        },
        {
          uniqueId: 'playpokemonleaderboard',
          name: t('menu_item_play_pokemon_leaderboard', 'Leaderboard'),
          itemType: 'externalLink',
          to: localizeLink(i18n.language, config.PLAY_LEADERBOARDS_URL)
        }
      ]
    } else {
      opSubmenu.subMenuItems = [
        {
          uniqueId: 'playpokemoncreateaccount',
          name: t('menu_item_play_pokemon_create_account', 'Create a Play! Pokémon Account'),
          itemType: 'internalLink',
          to: '/play-pokemon'
        }
      ]
    }

    mainMenuItems.push(opSubmenu)
  }

  const menuItems: MenuItemProps[] = [
    ...mainMenuItems,
    {
      uniqueId: 'line1',
      name: 'Line1',
      itemType: 'line'
    },
    {
      uniqueId: 'logout',
      name: t('menu_item_log_out', 'Log Out'),
      itemType: 'internalLink',
      iconSrc: 'https://static.pokemon.com/images/logout_filled_icon.svg',
      to: '/logout'
    }
  ]

  const setError = useCallback((text: string = '') => {
    setErrorText(text)
    navigate('/error')
    setLoading(false)
    setLoadingSubMenu(undefined)
  }, [navigate, setLoading])

  useEffect(() => {
    setLoading(true)
    fetchAccounts()
    setLoadingSubMenu(appsGamesSubmenu.uniqueId)
    getAuthorizedApps()
    getLoggedUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  useEffect(() => {
    if (accounts && selectedAccount && loggedUserProfile) {
      setLoading(false)
    }
  }, [accounts, selectedAccount, loggedUserProfile, setLoading])

  const tooManyAccountsError = t('cannot_create_too_many', 'You cannot create more child accounts without contacting customer service.')

  const handleAddChildClick = useCallback(async () => {
    if (!accessToken) {
      return
    }

    if (!ptc8688TamVeratad) {
      return navigate('/create')
    }

    setLoading(true)
    const result = await checkCanCreateChild(accessToken.token)
    setLoading(false)

    if (result === responses.not_verified) {
      setSelectedItemMainMenu(undefined)
      return navigate('/verify')
    }

    navigate('/create')
  }, [accessToken, navigate, ptc8688TamVeratad, setSelectedItemMainMenu])

  useEffect(() => {
    if (pathname === '/create' && accessToken) {
      (async () => {
        setLoading(true)
        setHideBar(false)
        setShowTopMenu(true)
        const result = await checkCanCreateChild(accessToken!.token)
        setLoading(false)

        switch (result) {
          case responses.cleared:
            break
          case responses.too_many:
            setError(tooManyAccountsError)
            break
          case responses.too_young:
            navigate(`/cannot_create/${result}`)
            break
          case responses.not_verified:
            navigate('/verify')
            break
          case responses.locked:
            if (ptc8688TamVeratad) {
              navigate('/verification-locked')
            } else {
              setError()
            }
            break
          default:
            setError()
            break
        }
      })()
    }
  }, [pathname, accessToken, navigate, setError, setHideBar, setShowTopMenu, tooManyAccountsError, ptc8688TamVeratad])

  const switchAccount = async (account: AccountPickerAccount) => {
    setLoading(true)
    setLoadingSubMenu(appsGamesSubmenu.uniqueId)
    let childGuid
    if (primaryUserAccount?.guid !== account.guid) {
      setIsChildView(true)
      if (screenSize === 'sm') {
        navigate('/profile')
      }
      childGuid = account.guid
    } else {
      setIsChildView(false)
    }
    setSelectedAccount(account)
    await getLoggedUserProfile(childGuid)
    await getAuthorizedApps(childGuid)
    setLoading(false)
  }

  const fetchAccounts = async () => {
    if (accessToken) {
      try {
        const resp = await retrieveAccounts(accessToken.token)
        if (resp.data && resp.data.accounts && resp.data.accounts.length > 0) {
          const accounts = resp.data.accounts
          setAccounts(accounts)
          setPrimaryUserAccount(accounts[0])
          setSelectedAccount((current) => !current ? accounts[0] : current)
        } else {
          setError()
        }
      } catch (e) {
        console.log(e)
        setError()
      }
    }
  }

  const getLoggedUserProfile = async (childGuid?: string) => {
    if (accessToken) {
      try {
        setLoggedUserProfile(undefined)
        const answer = await retrieveProfile(accessToken.token, childGuid)
        const profileInfo = answer.data
        if (profileInfo) {
          setLoggedUserProfile(profileInfo)
          if (!primaryUserProfile) {
            setPrimaryUserProfile(profileInfo)
          }
        }
      } catch (e) {
        console.log(e)
        setError()
      }
    }
  }

  const getAuthorizedApps = async (childGuid?: string) => {
    if (accessToken) {
      try {
        setAppList(undefined)
        const answer = await retrieveAppList(accessToken.token, childGuid)
        const appListApi: AppInfo[] = sortAppInfoArray(answer.data.apps)
        if (appListApi) {
          setLinksData(appListApi)
          setAppList(appListApi)
        }
        setLoadingSubMenu(undefined)
      } catch (e) {
        console.log(e)
        setError()
      }
    }
  }

  let screenSize = 'lg'

  if (mediumOrSmallerScreen) {
    screenSize = 'md'
  }

  if (smallOrSmallerScreen) {
    screenSize = 'sm'
  }

  const getDisplayName = () => {
    if (selectedAccount) {
      return displayName(selectedAccount?.first_name, selectedAccount?.last_name, selectedAccount?.screen_name, selectedAccount?.username)
    }
  }

  let mainWrapperClass = 'app-wrapper '
  if (smallOrSmallerScreen) {
    mainWrapperClass = 'app-wrapper-small '
  }

  if (!showTopMenu) {
    mainWrapperClass += 'white-background'
  }

  const inCreateView = pathname === '/create'

  return (
    <ModalContextProvider>
      <div className={mainWrapperClass}>
        {!inCreateView &&
          <SidebarMenu
            headerItem={HeaderLogo}
            footerItem={<Footer />}
            accountPicker={accounts && selectedAccount ? <AccountPicker selectedAccount={selectedAccount} dropdown={true} accounts={accounts} addChild={handleAddChildClick} changeAccount={(account: AccountPickerAccount) => { switchAccount(account) }} /> : undefined}
            menuItems={menuItems}
            screenSize={screenSize}
            mainMenuItemloading={loadingSubMenu}
          />
        }
        <AppWrapper>
          <LoadingContext.Provider value={{ isLoading, setLoading }}>
            {isChildView && primaryUserAccount && showTopMenu && !inCreateView &&
              <TopBar
                text={t('top_bar_message',
                  {
                    defaultValue: 'You are logged in as {{userDisplayName}}',
                    replace: {
                      userDisplayName: getDisplayName()
                    }
                  })
                }
                buttonText={t('topbar_button_text', 'Back to Your Account')}
                onClick={() => switchAccount(primaryUserAccount)}
              />
            }
            {showTopMenu &&
              // This Suspense is to support code splitting based on route. This reduces main bundle size, especially when a route is behind a feature flag and will never be navigated to anyway
              <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                  <Route path="/" element={<AuthWrapper />}>
                    <Route
                      path="/profile?"
                      element={<ProfileOverviewPage key={selectedAccount?.guid} setError={setError} getLoggedUserProfile={getLoggedUserProfile} />}
                    />
                    <Route path="/profile/change-email" element={<ChangeEmailPage key={selectedAccount?.guid} setError={setError} />} />
                    <Route
                      path="/profile/change-password"
                      element={<ChangePasswordPage key={selectedAccount?.guid} setError={setError} />}
                    />
                    <Route path="/profile/data-security" element={<PrivacyPage key={selectedAccount?.guid} setError={setError} />} />
                    {!userIsJp && (
                      <Route path="/profile/nintendo-link"
                            element={<NintendoLinkPage key={selectedAccount?.guid} switchAccount={switchAccount}
                                                       setError={setError}
                                                       getLoggedUserProfile={getLoggedUserProfile}/>}/>
                    )}
                  <Route path="/profile/mfa" element={<MfaPage setError={setError} isChild={isChildView}/>} />
                  <Route path="/app/:appId" element={<GenericAppGamePage key={selectedAccount?.guid} setError={setError} />} />
                  <Route path="/create" element={<ChildAccCreation key={selectedAccount?.guid} setError={setError} switchChildAcc={switchAccount} /> } />
                  {ptc8688TamVeratad &&
                    <>
                      <Route
                        path="/verify"
                        element={<VerifyPage setError={setError} />}
                      />
                      <Route path="/verification-locked" element={<ErrorPageVerificationLocked/>}/>
                    </>
                  }
                  <Route path="/cannot_create/too_young" element={<BaseContentBox><ErrorCustomBody title={t('cannot_create_child', 'You cannot create a child account')} message={t('cannot_create_too_young', 'You cannot create a child account at your current age.')} /></BaseContentBox>} />
                  {ptc8691TamOpSignupAndManagement && <Route path="/play-pokemon" element={<PlaySettingsPage setError={setError} />} />}
                  </Route>
                  <Route path="/logout" element={<LogoutPage />} />
                  <Route path="/callback" element={<Callback setError={setError} />} />
                  <Route path='/error' element={<ErrorPage message={errorText} />} />
                  <Route path="/404" element={<ErrorPageNotFound />} />
                  <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
              </Suspense>
            }
          </LoadingContext.Provider>
        </AppWrapper>
        {showTopMenu && <Mascots />}
      </div>
    </ModalContextProvider>
  )
}

export default App
