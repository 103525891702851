import React, { useCallback, useContext } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { CopyField } from '@pokemon/design-v2.copy-field'
import { Modal } from '@pokemon/design-v2.modal'
import { QrCode } from '@pokemon/design-v2.qr-code'
import { TextField } from '@pokemon/design-v2.text-field'
import { Button } from '@pokemon/design-v2.button'
import Validators from '../../utils/formValidators'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { setMfa } from '../../utils/mfaApi'
import './enrollment_modal.css'

export interface EnrollmentModalProps {
    open: boolean
    onClose: () => void
    qrData: string
    copyData: string
    enrollmentTicket: string
    setError: (text?: string) => void
    onSuccess: (text?: string) => void
    isChild?: boolean
}

interface EnabledMfaValues {
    totp: string
}

function validateCode (t: TFunction<'translation', undefined>) {
  return (code: any) => {
    return Validators.validateCode(code) ? null : t('mfa_code_invalid', 'Code must be 6 digits')
  }
}

export const EnrollmentModal = (props: EnrollmentModalProps) => {
  const { open, onClose, qrData, copyData, enrollmentTicket, setError, onSuccess } = props
  const { t } = useTranslation()
  const { accessToken, isChildView, selectedAccount } = useContext<AuthContextValue>(AuthContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const childGuid = isChildView ? selectedAccount?.guid : undefined

  const enableMfa = useCallback(async (values: EnabledMfaValues) => {
    try {
      if (enrollmentTicket) {
        await setMfa(accessToken!.token, {
          mfa_method: 'totp',
          enrollment_ticket: enrollmentTicket,
          totp: values.totp
        }, childGuid)
        onSuccess()
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        return { totp: t('mfa_code_incorrect', 'Code is incorrect, please check and try again') }
      }
      console.error(error)
      setError()
    }
  }, [t, accessToken, enrollmentTicket, setError, onSuccess, childGuid])

  return (
        <Modal
            id='enrollment-modal'
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            sx={{ textAlign: 'center' }}
        >
            <Typography data-testid="mfa_enrollment_step_1_title" variant="h5" component="h2">{t('mfa_setup_step_1_title', 'Step 1: Install Third Party Authenticator')}</Typography>
            { props.isChild
              ? <Typography data-testid="mfa_enrollment_step_1_description" variant="body1" sx={{ marginBottom: '32px' }}>{t('mfa_setup_step_1_text_child', 'You or your child will need to install an authenticator app first before you can set up multi-factor authentication. Within the authenticator app (e.g., Google Authenticator or Duo Mobile), you can either scan the barcode or enter the code below.')}</Typography>
              : <Typography data-testid="mfa_enrollment_step_1_description" variant="body1" sx={{ marginBottom: '32px' }}>{t('mfa_setup_step_1_text', 'You will need to install an authenticator app first before you can set up multi-factor authentication. Within the authenticator app (e.g., Google Authenticator or Duo Mobile), you can either scan the barcode or enter the code below.')}</Typography>
            }
            <section style={{ display: 'flex', justifyContent: 'space-around', flexFlow: 'row wrap' }}>
                <div data-dd-privacy="mask">
                  <QrCode value={qrData} size={450} style={{ width: '200px', height: '200px' }} qrStyle='dots'/>
                </div>
                <section className="enrollment-qr-section">
                    <CopyField
                        value={copyData}
                        displayValue={
                            <Typography data-testid="mfa_enrollment_step_1_code" variant="h6" component={'p'}>
                                {
                                    copyData.substring(0, 4) + ' ' + copyData.substring(4, 8) + ' ' + copyData.substring(8, 12) + ' ' + copyData.substring(12, 16)
                                }
                                <br/>
                                {
                                    copyData.substring(16, 20) + ' ' + copyData.substring(20, 24) + ' ' + copyData.substring(24, 28) + ' ' + copyData.substring(28, 32)
                                }
                            </Typography>
                        }
                        copyMessage={t('mfa_code_copy', 'Copy code')}
                        copiedMessage={t('mfa_code_copied', 'Copied')}
                    />
                </section>
            </section>
            <hr style={{ color: 'var(--light-grey)', marginTop: '32px', marginBottom: '32px' }}/>
            <Typography data-testid="mfa_setup_step_2_title" variant="h5" component="h2">{t('mfa_setup_step_2_title', 'Step 2: Enter Confirmation Code')}</Typography>
            <Typography data-testid="mfa_setup_step_2_text" variant="body1" sx={{ marginBottom: '32px' }}>{t('mfa_setup_step_2_text', 'Please enter the confirmation code you see on your authentication app.')}</Typography>
            <Form onSubmit={enableMfa} render={(form) => (
              <form onSubmit={form.handleSubmit}>
                <section style={{ display: 'flex', justifyContent: 'space-around', flexFlow: 'row wrap' }}>
                <Field name="totp" validate={validateCode(t)}>
                  {({ input, meta }) => (
                    <TextField
                      id="mfa-turn-on-code"
                      type="text"
                      sx={{ width: 1 }}
                      errorText={meta.touched && (meta.error || (!form.modifiedSinceLastSubmit && meta.submitError)) && <p className='input-error'>{meta.error || meta.submitError}</p>}
                      {...input}/>
                  )}
                </Field>
                </section>
                <Button
                    data-testid="mfa_setup_setp_2_continue_button"
                    type="submit"
                    disabled={form.submitting || form.hasValidationErrors || (form.hasSubmitErrors && !form.modifiedSinceLastSubmit)}
                    text={t('mfa_intro_button_continue', 'CONTINUE')}
                    style={{ width: '100%', marginTop: 10 }}/>
                <Button
                    onClick={onClose}
                    data-testid="mfa_setup_setp_2_cancel_button"
                    type="button"
                    variant={'outlined'}
                    text={t('mfa_intro_button_cancel', 'CANCEL')}
                    style={{ width: '100%', marginTop: 10 }}/>
              </form>
            )}/>
        </Modal>
  )
}
