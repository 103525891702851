import { countryCodeReplacementString } from '@tpci/i18next-ext'
import { StringMap } from 'i18next'

const overridesByStage = {
  live: {
    RECAPTCHA_SITE_KEY: '6LdD428fAAAAAONHdz5Ltgi-mOyfN_QUPj9JDb7O',
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '619bf56e90dd07171dfb66ca'
  },
  beta2: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333bfbb24d1410f8f91ce8',
    PRIVACY_POLICY_URL: `https://beta2.pokemon.com/${countryCodeReplacementString}/api/privacy-policy.json`,
    TERMS_URL: `https://beta2.pokemon.com/${countryCodeReplacementString}/api/terms-of-use.json`
  },
  dev: {
    OAUTH_CALLBACK_URL: 'http://localhost:3000/callback',
    TAM_URL: 'http://localhost:3000/'
  },
  perf: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '6333380c8d2ed91187a4e7f5'
  },
  ciTpci: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333c34ace91e11a38e27c6'
  },
  ciTc: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333c3fe4b12a11b46b48c8'
  },
  ciNiantic: {
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '63333c2a4021fd11d93023ad'
  }
} as { [k: string]: StringMap }

export function buildConfig (stage?: string, domain?: string) {
  const pcomBaseUrl = `https://www.${domain}/${countryCodeReplacementString}`
  return {
    // Add common config values here
    OAUTH_CLIENT_ID: 'trainer-account-management-frontend',
    OAUTH_SCOPES: 'openid',
    OAUTH_PROVIDER_URL: `https://access.${domain}`,
    OAUTH_LOGIN_URL: `https://access.${domain}/login`,
    OAUTH_CALLBACK_URL: `https://trainer.${domain}/callback`,
    TAMAPI_BASE_URL: `https://api.trainer.${domain}`,
    PRIVACY_POLICY_URL: `${pcomBaseUrl}/api/privacy-policy.json`,
    TERMS_URL: `${pcomBaseUrl}/api/terms-of-use.json`,
    PLAY_PAGE_URL: `${pcomBaseUrl}/pokemon-trainer-club/play-pokemon/events/`,
    PLAY_LEADERBOARDS_URL: `${pcomBaseUrl}/play-pokemon/leaderboards/`,
    TAM_URL: `https://trainer.${domain}/`,
    AAAPI_BASE_URL: `https://api.apps.${domain}`,
    ACAPI_BASE_URL: `https://api.join.${domain}`,
    MFAAPI_BASE_URL: `https://api.mfa.${domain}`,
    TOKEN_BASE_URL: `https://me.${domain}/me`,
    REACT_APP_LAUNCHDARKLY_CLIENT_ID: '633337f8f496a411faa39518',
    // Nintendo Link Configuration
    NINTENDO_CLIENT_ID: '456a52d269c25f54',
    NLINK_REDIRECT_URI: `https://trainer.${domain}/profile/nintendo-link`,
    NLINK_AUTHORIZATION_URL: 'https://accounts.nintendo.com/connect/1.0.0/authorize',
    STAGE: stage ?? 'alpha',
    ...(overridesByStage[stage!] ?? {})
  }
}

export default buildConfig(process.env.REACT_APP_STAGE, process.env.REACT_APP_DOMAIN)
